import React from "react";
import { useEffect } from "react";
import { Loader } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';

function FaceLiveness({faceLivenessAnalysis}) {
    const [loading, setLoading] = React.useState(true);
    const [sessionId, setSessionId] = React.useState(null)
   
    useEffect(() => {
        /*
         * API call to create the Face Liveness Session
         */
        // fetchCreateLiveness();
        // getSessionId()
      const sessionIdFromParams =  new URL(document.URL)?.search.split("session-id=")[1]
      setSessionId(sessionIdFromParams)
      setLoading(false);
      console.log("TEST close deeplink", "trential://payload")
    //   setTimeout(()=>{
    //     // window.history.back()
    //     // window.close()
    //     window.location.href = "https://kla123.netlify.app/"
    //   }, 3000)
    },[])

    /*
   * Get the Face Liveness Session Result
   */
    const handleAnalysisComplete = async () => {
        /*
         * API call to get the Face Liveness Session result
         */
        // faceLivenessAnalysis(data?.data)
        // window.location.href = "https://www.trential.com";
        // window.location.replace("trential://payload")
        redirectToApp()
    };

    const redirectToApp = () => {
        window.location.href = "trential://payload"
    };

    console.log('sessionId', sessionId)

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <FaceLivenessDetector
                    sessionId={sessionId}
                    region="ap-south-1"
                    onAnalysisComplete={handleAnalysisComplete}
                    onError={(error) => {
                        console.error(error);
                        redirectToApp()
                      }}
                />
            )}
        </>
    );
}

export default FaceLiveness;
